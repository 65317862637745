import * as actionTypes from './actionTypes';
import axios from '../../axios.config';
import {showNotification} from './mainWindow';
import {history} from '../../index';
import {getHotelDetail} from "./search";
import {createUrlWithParams} from "../../functions/url";


/*
 * GET customer profile
 */
export const getCustomerProfile = () => {
  return dispatch => {
    dispatch(getCustomerProfileStart())
    axios.get('/customer/profile')
      .then ( response => {
        dispatch(getCustomerProfileSuccess(response.data))
      })
      .catch(error => {
        dispatch(getCustomerProfileFail(error))
      })
  }
}

const getCustomerProfileStart = () => {
  return {
    type: actionTypes.GET_CUSTOMER_PROFILE_START,
  }
}

const getCustomerProfileSuccess = (res) => {
  return {
    type: actionTypes.GET_CUSTOMER_PROFILE_SUCCESS,
    res: res,
  }
}

const getCustomerProfileFail = (error) => {
  return {
    type: actionTypes.GET_CUSTOMER_PROFILE_FAIL,
    error: error,
  }
}


/*
 * PUT customer profile
 */
export const putCustomerProfile = (payload) => {
  return dispatch => {
    dispatch(putCustomerProfileStart())
    axios.put('/customer/profile', payload)
      .then ( response => {
        dispatch(putCustomerProfileSuccess(response.data))
        dispatch(showNotification('Änderung gespeichert','success'))
        dispatch(getCustomerProfile())
        window.scrollTo(0, 0)
        history.push('/dashboard/profile/')
      })
      .catch(error => {
        dispatch(showNotification('Leider ist ein Fehler aufgetreten.','error'))
        dispatch(putCustomerProfileFail(error))
      })
  }
}

const putCustomerProfileStart = () => {
  return {
    type: actionTypes.PUT_CUSTOMER_PROFILE_START,
  }
}

const putCustomerProfileSuccess = (res) => {
  return {
    type: actionTypes.PUT_CUSTOMER_PROFILE_SUCCESS,
    res: res,
  }
}

const putCustomerProfileFail = (error) => {
  return {
    type: actionTypes.PUT_CUSTOMER_PROFILE_FAIL,
    error: error,
  }
}


/*
 * GET customer invoice (all)
 */
export const getCustomerInvoice = () => {
  return dispatch => {
    dispatch(getCustomerInvoiceStart())
    axios.get('/customer/invoice/all/')
      .then ( response => {
        dispatch(getCustomerInvoiceSuccess(response.data))
      })
      .catch(error => {
        dispatch(getCustomerInvoiceFail(error))
      })
  }
}

const getCustomerInvoiceStart = () => {
  return {
    type: actionTypes.GET_CUSTOMER_INVOICE_START,
  }
}

const getCustomerInvoiceSuccess = (res) => {
  return {
    type: actionTypes.GET_CUSTOMER_INVOICE_SUCCESS,
    res: res,
  }
}

const getCustomerInvoiceFail = (error) => {
  return {
    type: actionTypes.GET_CUSTOMER_INVOICE_FAIL,
    error: error,
  }
}



/*
 * GET customer subscriptions (all)
 */
export const getCustomerSubscriptions = (selectedSubscriptionID) => {
  return dispatch => {
    dispatch(getCustomerSubscriptionsStart())
    axios.get('/customer/subscription/all')
      .then ( response => {
        // if no subscriptionID is selected use first active subscription as fallback
        let selectedSubscription = response.data?.filter(subscription => subscription?.subscriptionData?.status === 'active')?.[0]
        if(selectedSubscriptionID) {
          selectedSubscription = response.data?.filter(subscription => subscription?.subscriptionID === selectedSubscriptionID)?.[0]
        }
        dispatch(updateCustomerSelectedSubscription(selectedSubscription))
        dispatch(getCustomerSubscriptionsSuccess(response.data, selectedSubscription))
      })
      .catch(error => {
        dispatch(getCustomerSubscriptionsFail(error))
      })
  }
}

const getCustomerSubscriptionsStart = () => {
  return {
    type: actionTypes.GET_CUSTOMER_SUBSCRIPTIONS_START,
  }
}

const getCustomerSubscriptionsSuccess = (res, selectedSubscription) => {
  return {
    type: actionTypes.GET_CUSTOMER_SUBSCRIPTIONS_SUCCESS,
    res: res,
    selectedSubscription: selectedSubscription,
  }
}

const getCustomerSubscriptionsFail = (error) => {
  return {
    type: actionTypes.GET_CUSTOMER_SUBSCRIPTIONS_FAIL,
    error: error,
  }
}


/*
 * UPDATE existing subscription: selected nights (array)

export const updateCustomerBookingReservation = (nights) => {
  return dispatch => {
    dispatch({
      type: actionTypes.UPDATE_CUSTOMER_BOOKING_RESERVATION,
      res: nights,
    })
  }
}
 */



/*
 * UPDATE delta list of selected nights (cancel or book)
 */
export const updateCustomerBookingReservationDelta = (nights) => {
  return dispatch => {
    dispatch({
      type: actionTypes.UPDATE_CUSTOMER_BOOKING_RESERVATION_DELTA,
      res: nights,
    })
  }
}


/*
 * PUT customer booking reservation
 */
export const putCustomerBookingReservation = (payload) => {
  return dispatch => {
    dispatch(putCustomerBookingReservationStart())
    axios.put('/customer/reservation/update', payload)
      .then ( response => {
        dispatch(putCustomerBookingReservationSuccess(response.data))
        dispatch(showNotification('Änderung erfolgreich übermittelt','success'))
        dispatch(getCustomerSubscriptions(payload.subscriptionID))
      })
      .catch(error => {
        dispatch(showNotification('Leider ist ein Fehler aufgetreten.','error'))
        dispatch(putCustomerBookingReservationFail(error))
      })
  }
}

const putCustomerBookingReservationStart = () => {
  return {
    type: actionTypes.PUT_CUSTOMER_BOOKING_RESERVATION_START,
  }
}

const putCustomerBookingReservationSuccess = (res) => {
  return {
    type: actionTypes.PUT_CUSTOMER_BOOKING_RESERVATION_SUCCESS,
    res: res,
  }
}

const putCustomerBookingReservationFail = (error) => {
  return {
    type: actionTypes.PUT_CUSTOMER_BOOKING_RESERVATION_FAIL,
    error: error,
  }
}



/*
 * UPDATE customer subscription selection in GUI
 */
export const updateCustomerSelectedSubscription = (subscription) => {
  return (dispatch) => {
    const selectedSubscriptionHotelId = subscription?.subscriptionData?.BookingHotel?.hotelId
    if(selectedSubscriptionHotelId) dispatch(getHotelDetail(selectedSubscriptionHotelId, '', 4))

    dispatch({
      type: actionTypes.UPDATE_CUSTOMER_SELECTED_SUBSCRIPTION,
      res: subscription,
    })
  }
}



/*
 * UPDATE customer company employee selection in GUI
 */
export const updateCustomerCompanySelectedEmployee = (invitationID) => {
  return (dispatch) => {

    dispatch({
      type: actionTypes.UPDATE_CUSTOMER_COMPANY_SELECTED_EMPLOYEE,
      res: invitationID,
    })
  }
}



/*
 * GET all company employee invites by customer session
 */

export const getCustomerCompanyInvite = (selectedEmployeeID=null) => {
  return dispatch => {
    dispatch(getCustomerCompanyInviteStart())
    axios.get('/customer/company/invite/all')
      .then ( response => {
        dispatch(getCustomerCompanyInviteSuccess(response?.data))
        if(selectedEmployeeID) {
          dispatch(updateCustomerCompanySelectedEmployee(selectedEmployeeID))
        } else {
          dispatch(updateCustomerCompanySelectedEmployee(response?.data?.[0] || null))
        }
      })
      .catch(error => {
        console.log('getCustomerCompanyInvite',error)
        dispatch(getCustomerCompanyInviteFail(error))
      })
  }
}

const getCustomerCompanyInviteStart = () => {
  return {
    type: actionTypes.GET_CUSTOMER_COMPANY_INVITE_START,
  }
}

const getCustomerCompanyInviteSuccess = (res) => {
  return {
    type: actionTypes.GET_CUSTOMER_COMPANY_INVITE_SUCCESS,
    res: res,
  }
}

const getCustomerCompanyInviteFail = (error) => {
  return {
    type: actionTypes.GET_CUSTOMER_COMPANY_INVITE_FAIL,
    error: error,
  }
}


/*
 * POST company employee invite by customer session
 */
export const postCustomerCompanyInvite = (payload) => {
  return dispatch => {
    dispatch(postCustomerCompanyInviteStart())
    axios.post('/customer/company/invite/', payload)
      .then(response => {
        dispatch(postCustomerCompanyInviteSuccess(response.data))
        dispatch(showNotification('Änderung gespeichert','success'))
        dispatch(getCustomerCompanyInvite())
        window.scrollTo(0, 0)
        history.push('/dashboard/company/invite/')
      })
      .catch(error => {
        dispatch(showNotification(error?.response?.data?.message || 'Leider ist ein Fehler aufgetreten.','error'))
        dispatch(postCustomerCompanyInviteFail(error))
      })
  }
}

const postCustomerCompanyInviteStart = () => {
  return {
    type: actionTypes.POST_CUSTOMER_COMPANY_INVITE_START,
  }
}

const postCustomerCompanyInviteSuccess = (res) => {
  return {
    type: actionTypes.POST_CUSTOMER_COMPANY_INVITE_SUCCESS,
    res: res,
  }
}

const postCustomerCompanyInviteFail = (error) => {
  return {
    type: actionTypes.POST_CUSTOMER_COMPANY_INVITE_FAIL,
    error: error,
  }
}



/*
 * PUT company employee invite by inviteID
 */
export const putCustomerCompanyInvite = (inviteID, payload) => {
  return dispatch => {
    dispatch(putCustomerCompanyInviteStart())
    axios.put('/customer/company/invite/'+inviteID, payload)
      .then ( response => {
        dispatch(putCustomerCompanyInviteSuccess(response.data))
        dispatch(showNotification('Änderung gespeichert','success'))
        dispatch(getCustomerCompanyInvite())
        window.scrollTo(0, 0)
        history.push('/dashboard/company/invite/')
      })
      .catch(error => {
        dispatch(showNotification('Leider ist ein Fehler aufgetreten.','error'))
        dispatch(putCustomerCompanyInviteFail(error))
      })
  }
}

const putCustomerCompanyInviteStart = () => {
  return {
    type: actionTypes.PUT_CUSTOMER_COMPANY_INVITE_START,
  }
}

const putCustomerCompanyInviteSuccess = (res) => {
  return {
    type: actionTypes.PUT_CUSTOMER_COMPANY_INVITE_SUCCESS,
    res: res,
  }
}

const putCustomerCompanyInviteFail = (error) => {
  return {
    type: actionTypes.PUT_CUSTOMER_COMPANY_INVITE_FAIL,
    error: error,
  }
}



/*
 * DELETE company employee invite by inviteID
 */
export const deleteCustomerCompanyInvite = (inviteID) => {
  return dispatch => {
    dispatch(deleteCustomerCompanyInviteStart())
    axios.put('/customer/company/invite/archive/'+inviteID)
      .then ( response => {
        dispatch(deleteCustomerCompanyInviteSuccess(response.data))
        dispatch(showNotification('Eintrag gelöscht','success'))
        dispatch(getCustomerCompanyInvite())
        window.scrollTo(0, 0)
        history.push('/dashboard/company/invite/')
      })
      .catch(error => {
        dispatch(showNotification('Leider ist ein Fehler aufgetreten.','error'))
        dispatch(deleteCustomerCompanyInviteFail(error))
      })
  }
}

const deleteCustomerCompanyInviteStart = () => {
  return {
    type: actionTypes.DELETE_CUSTOMER_COMPANY_INVITE_START,
  }
}

const deleteCustomerCompanyInviteSuccess = (res) => {
  return {
    type: actionTypes.DELETE_CUSTOMER_COMPANY_INVITE_SUCCESS,
    res: res,
  }
}

const deleteCustomerCompanyInviteFail = (error) => {
  return {
    type: actionTypes.DELETE_CUSTOMER_COMPANY_INVITE_FAIL,
    error: error,
  }
}



/*
 * GET attributes (unique) of customers booking history 
 */
export const getBookingHistoryAttributes = () => {
  return dispatch => {
    dispatch(getBookingHistoryAttributesStart())
    axios.get('/customer/report/historyAttributes')
      .then ( response => {
        dispatch(getBookingHistoryAttributesSuccess(response?.data))
      })
      .catch(error => {
        dispatch(getBookingHistoryAttributesFail(error))
      })
  }
}

const getBookingHistoryAttributesStart = () => {
  return {
    type: actionTypes.GET_BOOKING_HISTORY_ATTRIBUTES_START,
  }
}

const getBookingHistoryAttributesSuccess = (res) => {
  return {
    type: actionTypes.GET_BOOKING_HISTORY_ATTRIBUTES_SUCCESS,
    res: res,
  }
}

const getBookingHistoryAttributesFail = (error) => {
  return {
    type: actionTypes.GET_BOOKING_HISTORY_ATTRIBUTES_FAIL,
    error: error,
  }
}





/*
 * GET customers booking history 
 */
export const getBookingHistory = (filter) => {
  return dispatch => {
    dispatch(getBookingHistoryStart())
    const url = createUrlWithParams('/customer/report/history',filter)
    axios.get(url)
      .then ( response => {
        dispatch(getBookingHistorySuccess(response?.data))
      })
      .catch(error => {
        dispatch(getBookingHistoryFail(error))
      })
  }
}

const getBookingHistoryStart = () => {
  return {
    type: actionTypes.GET_BOOKING_HISTORY_START,
  }
}

const getBookingHistorySuccess = (res) => {
  return {
    type: actionTypes.GET_BOOKING_HISTORY_SUCCESS,
    res: res,
  }
}

const getBookingHistoryFail = (error) => {
  return {
    type: actionTypes.GET_BOOKING_HISTORY_FAIL,
    error: error,
  }
}


/*
 * UPDATE selection in GUI
 */
export const updateBookingHistoryFilter = (parameters) => {
  return (dispatch) => {

    dispatch({
      type: actionTypes.UPDATE_BOOKING_HISTORY_FILTER,
      res: parameters,
    })
  }
}