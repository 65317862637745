import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../store/actions";
import {useHistory} from "react-router";
import {Controller, useForm} from 'react-hook-form';
import {Box, FormControl, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import {ButtonBack} from "../components/Buttons";
import LoadingSpinner from "../assets/svg/LoadingSpinner";

const CustomerCompanyInviteAdd = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const companyInvitesLoading = useSelector(state => state.customer.companyInvitesLoading)

  /* react hook form */
  const { control, handleSubmit } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      selfService: false,
      language: 'de',
      costCenter: '',
    }
  })

  const onSubmit = data => {
    dispatch(actions.postCustomerCompanyInvite(data))
  }

  return (
    <div className="container max-w-screen-lg mx-auto">

      <div className="mt-8 mb-16 mx-4">

        <ButtonBack target={'/dashboard/company/invite/'} />

        <div className="text-center text-3xl font-light mt-8">Personen</div>
        <div className="text-center text-md font-light mt-2">Weitere Personen zu deinem Unternehmensaccount hinzufügen.</div>

        <form onSubmit={handleSubmit(onSubmit)} className="mt-6">
          <div className="mx-auto p-4 rounded-lg sm:w-112">
            <div>

              <Box
                sx={{
                  textAlign: 'center',
                  '& .MuiTextField-root': {mt: 1, p: 0, width: '90%'},
                  '& .MuiFormControl-root': {mt: 1, p: 0, width: '90%'},
                }}
              >
                <div className="">
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{
                      required: 'Bitte gib deinen Vornamen an',
                    }}
                    render={({field, fieldState}) => (
                      <TextField
                        {...field}
                        label="Vorname"
                        size="small"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{
                      required: 'Bitte gib deinen Nachnamen an',
                    }}
                    render={({field, fieldState}) => (
                      <TextField
                        {...field}
                        label="Nachname"
                        size="small"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />

                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: 'Bitte gib eine E-Mail-Adresse an',
                    }}
                    render={({field, fieldState}) => (
                      <TextField
                        {...field}
                        label="E-Mail"
                        size="small"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />
                </div>

                <div className="mt-4">
                  <Controller
                    name="selfService"
                    control={control}
                    render={({field, fieldState}) => (
                      <FormControl fullWidth sx={{
                        textAlign: 'left',
                        '& .MuiTextField-root': {mt: 1, p: 0, width: '90%'},
                        '& .MuiFormControl-root': {mt: 1, p: 0, width: '90%'},
                      }}>
                        <InputLabel id="selfService-label">Self-Service</InputLabel>
                        <Select
                          labelId="selfService-label"
                          label="selfService"
                          size="small"
                          value={field.value}
                          onChange={field.onChange}
                          error={!!fieldState.error}
                        >
                          <MenuItem value={true}>Ja</MenuItem>
                          <MenuItem value={false}>Nein</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />

                  <Controller
                    name="language"
                    control={control}
                    render={({field, fieldState}) => (
                      <FormControl fullWidth sx={{
                        textAlign: 'left',
                        '& .MuiTextField-root': {mt: 1, p: 0, width: '90%'},
                        '& .MuiFormControl-root': {mt: 1, p: 0, width: '90%'},
                      }}>
                        <InputLabel id="language-label">Sprache</InputLabel>
                        <Select
                          labelId="language-label"
                          label="language"
                          size="small"
                          value={field.value}
                          onChange={field.onChange}
                          error={!!fieldState.error}
                        >
                          <MenuItem value={'de'}>Deutsch</MenuItem>
                          <MenuItem value={'en'}>Englisch</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />

                  <Controller
                    name="costCenter"
                    control={control}
                    render={({field, fieldState}) => (
                      <TextField
                        {...field}
                        label="Kostenstelle (optional)"
                        size="small"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />
                </div>

              </Box>
            </div>

            <div className="text-sm text-gray-500 mt-3">
              Die eingeladene Person bekommt eine E-Mail mit Zugangsdaten.<br/>
              Mit der Self-Service Option kann sie/er selbst Nächte buchen oder stornieren.
            </div>

          </div>
          <div className="text-center mx-auto mt-2">
            {companyInvitesLoading
              ? <button
                className="inline-flex items-center justify-center btn bg-gray-400 text-white text-lg rounded p-2 w-56 cursor-not-allowed"
              >
                <LoadingSpinner color="white"/> Einladen
              </button>
              : <button
                type="submit"
                className="btn bg-primary text-white text-lg hover:bg-secondaryBlue rounded p-2 w-56"
                >
                  Einladen
                </button>
            }


          </div>
          <div className="text-center mx-auto mt-2">
            <button
              className="btn bg-secondaryLightGray text-white text-lg hover:bg-secondaryGray rounded p-2 w-56 m-2"
              onClick={() => {history.push('/dashboard/company/invite/'); window.scrollTo(0, 0)}}
            >Abbrechen</button>
          </div>
        </form>

      </div>

    </div>
  )
}

export default CustomerCompanyInviteAdd
