import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../store/actions";
import {useHistory} from "react-router";
import {ButtonBack} from "../components/Buttons";
import LoadingSpinner from "../assets/svg/LoadingSpinner";
import {useParams} from "react-router-dom";

const CustomerCompanyInviteEdit = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { invitationID } = useParams()

  const companyInvitesLoading = useSelector(state => state.customer.companyInvitesLoading)
  const companyInvitesUserList = useSelector(state => state.customer.companyInvitesUserList)

  const selectedUser = companyInvitesUserList.filter(x => x?.invitationID === invitationID)?.[0]

  // First useEffect to fetch data
  useEffect(() => {
    dispatch(actions.getCustomerCompanyInvite())
  }, [dispatch])

  const onSubmit = data => {
    dispatch(actions.deleteCustomerCompanyInvite(invitationID))
  }

  return (
    <div className="container max-w-screen-lg mx-auto">

      <div className="mt-8 mb-16 mx-4">

        <ButtonBack target={'/dashboard/company/invite/'} />

        <div className="text-center text-3xl font-light mt-8">Personen</div>
        <div className="text-center text-md font-light mt-2">Gast aus Unternehmensaccount entfernen.</div>

        <form className="mt-6">
          <div className="mx-auto p-4 rounded-lg sm:w-112">
            <div className="text-center border border-gray-200 bg-gray-50">
              <div className="font-semibold">{selectedUser?.firstName} {selectedUser?.lastName}</div>
              <div>{selectedUser?.email}</div>
            </div>

            <div className="text-sm text-gray-500 mt-3">
              Nach Archivierung ist die Person nicht mehr als Gast auswählbar.
              Der Self-Service Zugang wird gesperrt.
              Historische oder aktuelle Buchungen bleiben davon unberührt.<br/>
            </div>
          </div>


          <div className="text-center mx-auto mt-3">
            {companyInvitesLoading
              ? <button
                className="inline-flex items-center justify-center btn bg-gray-400 text-white text-lg rounded p-2 w-56 cursor-not-allowed"
              >
                <LoadingSpinner color="white"/> Archivieren
              </button>
              : <button
                type="button"
                className="btn bg-orange-600 text-white text-lg hover:bg-orange-700 rounded p-2 w-56"
                onClick={onSubmit}
              >
                Archivieren
              </button>
            }


          </div>
          <div className="text-center mx-auto mt-2">
            <button
              className="btn bg-secondaryLightGray text-white text-lg hover:bg-secondaryGray rounded p-2 w-56 m-2"
              onClick={() => {
                history.push('/dashboard/company/invite/');
                window.scrollTo(0, 0)
              }}
            >Abbrechen
            </button>
          </div>
        </form>

      </div>

    </div>
  )
}

export default CustomerCompanyInviteEdit
