import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../store/actions";
import {useHistory} from "react-router";
import {Controller, useForm} from 'react-hook-form';
import {Box, FormControl, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import {ButtonBack} from "../components/Buttons";
import LoadingSpinner from "../assets/svg/LoadingSpinner";
import {useParams} from "react-router-dom";
import {InformationCircleIcon} from "@heroicons/react/24/outline";
import {ChevronRightIcon} from "@heroicons/react/24/solid";
import {Disclosure} from "@headlessui/react";

const CustomerCompanyInviteEdit = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { invitationID } = useParams()

  const companyInvitesLoading = useSelector(state => state.customer.companyInvitesLoading)
  const companyInvitesUserList = useSelector(state => state.customer.companyInvitesUserList)

  const selectedUser = companyInvitesUserList.filter(x => x?.invitationID === invitationID)?.[0]

  // React hook form
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      selfService: false,
      language: 'de',
      costCenter: '',
      deputyBooker: false,
      deputyAdmin: false,
    }
  })

  // First useEffect to fetch data
  useEffect(() => {
    dispatch(actions.getCustomerCompanyInvite())
  }, [dispatch])

  // Set form values once when selectedUser is available
  useEffect(() => {
    if (selectedUser) {
      const defaultValues = {
        firstName: selectedUser.firstName || '',
        lastName: selectedUser.lastName || '',
        email: selectedUser.email || '',
        selfService: selectedUser.selfService || false,
        language: selectedUser.language || 'de',
        costCenter: selectedUser.costCenter || '',
        deputyBooker: selectedUser.deputyBooker || false,
        deputyAdmin: selectedUser.deputyAdmin || false,
      }
      reset(defaultValues)
    }
  }, [selectedUser, reset])

  const onSubmit = data => {
    dispatch(actions.putCustomerCompanyInvite(invitationID, data))
  }

  return (
    <div className="container max-w-screen-lg mx-auto">

      <div className="mt-8 mb-16 mx-4">

        <ButtonBack target={'/dashboard/company/invite/'} />

        <div className="text-center text-3xl font-light mt-8">Personen</div>
        <div className="text-center text-md font-light mt-2">Gastdetails aktualisieren.</div>

        <form onSubmit={handleSubmit(onSubmit)} className="mt-6">
          <div className="mx-auto p-4 rounded-lg sm:w-112">
            <div>

              <Box
                sx={{
                  textAlign: 'center',
                  '& .MuiTextField-root': {mt:1, p:0, width:'90%'},
                  '& .MuiFormControl-root': {mt:1, p:0, width:'90%'},
                }}
              >
                <div className="">
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{
                      required: 'Bitte gib deinen Vornamen an',
                    }}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label="Vorname"
                        size="small"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{
                      required: 'Bitte gib deinen Nachnamen an',
                    }}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label="Nachname"
                        size="small"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />

                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: 'Bitte gib eine E-Mail-Adresse an',
                    }}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label="E-Mail"
                        size="small"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />

                </div>

                <div className="mt-4">
                  <Controller
                    name="selfService"
                    control={control}
                    render={({ field,fieldState}) =>  (
                      <FormControl fullWidth sx={{
                        textAlign: 'left',
                        '& .MuiTextField-root': {mt:1, p:0, width:'90%'},
                        '& .MuiFormControl-root': {mt:1, p:0, width:'90%'},
                      }}>
                        <InputLabel id="selfService-label">Self-Service</InputLabel>
                        <Select
                          labelId="selfService-label"
                          label="selfService"
                          size="small"
                          value={field.value}
                          onChange={field.onChange}
                          error={!!fieldState.error}
                        >
                          <MenuItem value={true}>Ja</MenuItem>
                          <MenuItem value={false}>Nein</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />

                  <Controller
                    name="language"
                    control={control}
                    render={({field, fieldState}) => (
                      <FormControl fullWidth sx={{
                        textAlign: 'left',
                        '& .MuiTextField-root': {mt: 1, p: 0, width: '90%'},
                        '& .MuiFormControl-root': {mt: 1, p: 0, width: '90%'},
                      }}>
                        <InputLabel id="language-label">Sprache</InputLabel>
                        <Select
                          labelId="language-label"
                          label="language"
                          size="small"
                          value={field.value}
                          onChange={field.onChange}
                          error={!!fieldState.error}
                        >
                          <MenuItem value={'de'}>Deutsch</MenuItem>
                          <MenuItem value={'en'}>Englisch</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />

                  <Controller
                    name="costCenter"
                    control={control}
                    render={({ field,fieldState}) =>  (
                      <TextField
                        {...field}
                        label="Kostenstelle (optional)"
                        size="small"
                        value={field.value}
                        onChange={field.onChange}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        variant="outlined"
                      />
                    )}
                  />
                </div>


                <Disclosure as="div" className="mt-6" key={'disclosure1'} defaultOpen={false}>
                  {({open}) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium bg-gray-50  hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-75">
                        <span className="text-gray-500 font-semibold">Stellvertretung Account Admin</span>
                        <ChevronRightIcon
                          className={`${
                            open ? 'rotate-90 transform' : ''
                          } h-5 w-5 text-blue-900`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="pt-2 pb-2 text-sm text-gray-500 border-l-2 border-teal-100">
                        <div className="mt-2">
                          <Controller
                            name="deputyBooker"
                            control={control}
                            render={({field, fieldState}) => (
                              <FormControl fullWidth sx={{
                                textAlign: 'left',
                                '& .MuiTextField-root': {mt: 1, p: 0, width: '90%'},
                                '& .MuiFormControl-root': {mt: 1, p: 0, width: '90%'},
                              }}>
                                <InputLabel id="selfService-label">Buchen</InputLabel>
                                <Select
                                  labelId="deputyBooker-label"
                                  label="deputyBooker"
                                  size="small"
                                  value={field.value}
                                  onChange={field.onChange}
                                  error={!!fieldState.error}
                                >
                                  <MenuItem value={true}>Ja</MenuItem>
                                  <MenuItem value={false}>Nein</MenuItem>
                                </Select>
                              </FormControl>
                            )}
                          />
                          <div
                            className="mt-2 text-sm text-gray-600 flex flex-row bg-teal-50 text-start rounded-lg py-1 mx-5">
                            <InformationCircleIcon className="flex-none w-5 h-5 m-2"/>
                            <span>Darf für andere Personen Übernachtungen buchen sowie bestehende Buchungen stornieren.</span>
                          </div>
                        </div>

                        <div className="mt-5">
                          <Controller
                            name="deputyAdmin"
                            control={control}
                            render={({field, fieldState}) => (
                              <FormControl fullWidth sx={{
                                textAlign: 'left',
                                '& .MuiTextField-root': {mt: 1, p: 0, width: '90%'},
                                '& .MuiFormControl-root': {mt: 1, p: 0, width: '90%'},
                              }}>
                                <InputLabel id="selfService-label">Account</InputLabel>
                                <Select
                                  labelId="deputyAdmin-label"
                                  label="deputyAdmin"
                                  size="small"
                                  value={field.value}
                                  onChange={field.onChange}
                                  error={!!fieldState.error}
                                >
                                  <MenuItem value={true}>Ja</MenuItem>
                                  <MenuItem value={false}>Nein</MenuItem>
                                </Select>
                              </FormControl>
                            )}
                          />
                          <div
                            className="mt-2 text-sm text-gray-600 flex flex-row bg-teal-50 text-start rounded-lg py-1 mx-5">
                            <InformationCircleIcon className="flex-none w-5 h-5 m-2"/>
                            <span>Darf weitere Personen zum Unternehmensaccount einladen, umbennen oder archivieren. Kann Rechnungen sehen und das Zahlungsmittel ändern.</span>
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>

              </Box>
            </div>

          </div>
          <div className="text-center mx-auto mt-3">
            {companyInvitesLoading
              ? <button
                className="inline-flex items-center justify-center btn bg-gray-400 text-white text-lg rounded p-2 w-56 cursor-not-allowed"
              >
                <LoadingSpinner color="white"/> Änderung speichern
              </button>
              : <button
                type="submit"
                className="btn bg-primary text-white text-lg hover:bg-secondaryBlue rounded p-2 w-56"
              >
                Änderung speichern
              </button>
            }


          </div>
          <div className="text-center mx-auto mt-2">
            <button
              className="btn bg-secondaryLightGray text-white text-lg hover:bg-secondaryGray rounded p-2 w-56 m-2"
              onClick={() => {
                history.push('/dashboard/company/invite/');
                window.scrollTo(0, 0)
              }}
            >Abbrechen
            </button>
          </div>
        </form>

      </div>

    </div>
  )
}

export default CustomerCompanyInviteEdit
